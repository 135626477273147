import React, { useState, useEffect } from "react";
import style from "./style.module.css";
import callImg from "../../asset/images/call.png";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import CallForm from "./CallMeForm";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CallPopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={`${style.popupContainer} ${
        opened ? style.openedContainer : ""
      }`}
    >
      <section className={`${style.sectionPopup}`}>
        {showPopup && (
          <div
            className={`${style.closeIcon} `}
            onClick={() => setShowPopup(false)}
          >
            <FontAwesomeIcon icon={faTimes} />{" "}
          </div>
        )}
        <div
          id="popup-root"
          className={`${style.popupRoot} ${opened ? style.openedPopRoot : ""}`}
        ></div>
        {showPopup && (
          <Popup
            arrow={false}
            trigger={
              <div
                className={`${style.popupTrigger} ${
                  opened ? style.opened : ""
                }`}
              >
                <img src={callImg} alt="call" />
                <div className={style.popupInfo}>
                  <h3>მოითხოვე დარეკვა</h3>
                  <p>ჩვენ მალე დაგიკავშირდებით!</p>
                </div>
              </div>
            }
            position="bottom right"
            closeOnDocumentClick
            keepTooltipInside
            onOpen={() => setOpened(true)}
            onClose={() => setOpened(false)}
          >
            <div className={`${style.popupContent} `}>
              <CallForm />
            </div>
          </Popup>
        )}
      </section>
    </div>
  );
};

export default CallPopup;
