import React from "react";
import style from "./style.module.css";
import PagesLeftHeader from "../pagesHeader/pagesLeftHeader";
import Navigation from "../navigation/Navigation";
import Contact from "../footer/contact/Contact";
import CompanyInfo from "../footer/companyInfo/Info";
import projectDetailsImg from "../../asset/images/projectPage/project.jpg";
import PageHeaderTitle from "../pagesMainHeaders/pagesInfo";
import ContactInfo from "./contactInfo/ContactInfo";
import Map from "../map/Map";

const ContactPage = () => {
  return (
    <>
      <header className={`${style.headerContainer} `}>
        <PagesLeftHeader />
        <Navigation />
      </header>
      <main>
        <PageHeaderTitle title="კონტაქტი" image={projectDetailsImg} />
        <ContactInfo />
        <Map />
      </main>

      <footer className="footer">
        <Contact projects={true} bedRooms={false} />
        <CompanyInfo />
      </footer>
    </>
  );
};

export default ContactPage;
