import React, { useContext, useState, useEffect } from "react";
import style from "./style.module.css";
import { Link, NavLink } from "react-router-dom";
import { ProjectsCtrx } from "../store/projectListPrv";
import NavigationMobile from "./navigationMobile";

const Navigation = () => {
  const { projectChoosed } = useContext(ProjectsCtrx);

  const [isFixed, setIsFixed] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 80) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <nav
        className={`${style.navDesktop}  navbar-desctop ${
          isFixed ? style.fixed : ""
        }`}
      >
        <div className={`${style.container}`}>
          <Link to="/" className={`${style.brand} `}>
            cptlgroup<span className="text-primary">.</span>ge
            {/* <img src={logo} alt="logo" /> */}
          </Link>
          <ul className={style.navbarDescMenu}>
            <li>
              <NavLink
                end
                to={`/${projectChoosed}` || "/"}
                className={({ isActive }) => (isActive ? style.active : "")}
              >
                მთავარი
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/${projectChoosed}/about-company`}
                className={({ isActive }) => (isActive ? style.active : "")}
              >
                ჩვენს შესახებ
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/${projectChoosed}/projects`}
                className={({ isActive }) => (isActive ? style.active : "")}
              >
                პროექტები
              </NavLink>
            </li>

            <li>
              <NavLink
                to={`/${projectChoosed}/contact`}
                className={({ isActive }) => (isActive ? style.active : "")}
              >
                კონტაქტი
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
      <NavigationMobile />
    </>
  );
};

export default Navigation;
