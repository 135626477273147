import React from "react";
import style from "./style.module.css";
import { Link } from "react-router-dom";

const PagesLeftHeader = () => {
  return (
    <>
      <div className={style.brandPanel}>
        <Link to="/" className={`${style.brand}`}>
          cptlgroup<span className="text-primary">.</span>ge
        </Link>
      </div>
      <div className={style.headerPhone}>
        <a href="tel:+995595339337">+995 595 339 337</a>
      </div>
      <div className={style.verticalPanelContent}>
        <div className={style.verticalPanelInfo}>
          <div className={style.line}></div>
        </div>
        <ul className={style.socialList}>
          <li>
            <a
              href="https://www.facebook.com/capitalgroup01"
              target="_blank"
              className="fa fa-facebook"
              rel="noreferrer"
            ></a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/capital___group/?fbclid=IwAR2ZxgpvWsF3qlc58ddBzfBYaAcQgg2K-dyiwyxX4UPYjEg-VmPznUwiEOY"
              target="_blank"
              className="fa fa-instagram"
              rel="noreferrer"
            ></a>
          </li>
          {/* <li>
            <a href="#" className="fa fa-twitter"></a>
          </li> */}
        </ul>
      </div>
    </>
  );
};

export default PagesLeftHeader;
