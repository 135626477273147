import React, { useMemo } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "100%",
};

export default function GoogleMapComponent() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAeo54MKyMoTWQIYRrqWEhsm-ePAIBCskg",
  });

  if (!isLoaded) return <div>Loading....</div>;

  return <Map />;
}

function Map() {
  const center = useMemo(
    () => ({
      lat: 41.71214056166371,
      lng: 44.76090461468487,
    }),
    []
  );

  const markers = useMemo(
    () => [
      { lat: 41.71214056166371, lng: 44.76090461468487 },
      // { lat: 41.7, lng: 44.8 },
      // { lat: 41.71, lng: 44.81 },
    ],
    []
  );

  return (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={16}>
      {markers.map((marker, index) => (
        <MarkerF key={index} position={marker} />
      ))}
      {/* <MarkerF position={{ lat: 41.706051, lng: 44.807474 }} /> */}
    </GoogleMap>
  );
}
