import React from "react";
import style from "./style.module.css";

const ItemImageInfo = ({ itemInfo }) => {
  return (
    <section className={`${style.itemInfo} `}>
      <p>კვადრატი : {itemInfo?.kvadrati}m/2</p>
      <p>ფასი : {itemInfo?.kvPrice}$</p>
      {/* <p>სართული : {itemInfo?.floor}</p> */}
      <p>აივანი : {itemInfo?.balcony}</p>
      <p>საძინებელი : {itemInfo?.bedroom}</p>
      <p>სველი წერტილი : {itemInfo?.bathroom}</p>
    </section>
  );
};

export default ItemImageInfo;
