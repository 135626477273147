import React from "react";
import style from "./style.module.css";

const Info = () => {
  return (
    <section className="about section">
      <div className={`containers`}>
        <div className={style.entry}>
          <h3 className={style.entryTitle}>
            კაპიტალ ჯგუფი
            <span className="text-primary">.</span>
          </h3>
          <p className={style.entryText}>
            კაპიტალ ჯგუფი აერთიანებს თბილისისა და აჭარის მაშტაბით მიმდინარე და
            დასრულებულ პროექტებს.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Info;
