import React, { useState, createContext } from "react";

export const DashbCtrx = createContext({
  slideShowCount: 1,
  setSlideShowCount: () => {},
  currentSlideShow: 1,
  setCurrentSlideshow: () => {},
});

export const CounterProvider = ({ children }) => {
  const [slideShowCount, setSlideShowCount] = useState(1);
  const [currentSlideShow, setCurrentSlideshow] = useState(0);

  return (
    <DashbCtrx.Provider
      value={{
        slideShowCount,
        setSlideShowCount,
        currentSlideShow,
        setCurrentSlideshow,
      }}
    >
      {children}
    </DashbCtrx.Provider>
  );
};
