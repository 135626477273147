import React from "react";
import style from "./style.module.css";
import defaultProjectDesc from "../../../../asset/images/defaultProjectDesc.jpg";

const projectDescription = ({ currentProject }) => {
  return (
    <section className={style.projectDetails}>
      <div className={style.container}>
        <div className={style.projectDetailsItem}>
          <div className={`row ${style.mobailResponsive}`}>
            <div className={`${style.projectDetailsInfo} fadeInLeft`}>
              <h3 className={style.projectDetailsTitle}>
                პროექტის აღწერა
                <br />
              </h3>
              <p className={style.projectDetailsDesc}>
                {currentProject?.description.text}
              </p>
            </div>
            <div
              className={`${style.projectDetailsImg} col-md-8 col-md-offset-4`}
            >
              <img
                alt="proj"
                className="img-responsive"
                src={
                  currentProject?.image[1] ||
                  currentProject?.image[0] ||
                  defaultProjectDesc
                }
              />
            </div>
          </div>
        </div>
        <div className={style.projectDetailsItem}>
          <div className={`row ${style.mobailResponsiveSecond}`}>
            <div
              className={`${style.projectDetailsImg} ${style.projectDetailsImgSecond} col-md-8`}
            >
              <img
                alt="proj"
                className="img-responsive"
                src={
                  currentProject?.image[2] ||
                  currentProject?.image[1] ||
                  defaultProjectDesc
                }
              />
            </div>
            <div
              className={`${style.projectDetailsInfo} ${style.projectDetailsInfoSecond}   fadeInRight`}
            >
              <h3 className={style.projectDetailsTitle}>
                {currentProject?.description.facade}
                <br />
              </h3>
              <p className={style.projectDetailsDesc}>
                {currentProject?.description.yard}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default projectDescription;
