import React from "react";
import style from "./style.module.css";

import ItemHouse from "./itemHouse/ItemHouse";

const ProjectItems = ({ currentProject }) => {
  const id = currentProject?._id;

  return (
    <section className={style.projectItems}>
      {currentProject?.flats.map((el, index) => (
        <ItemHouse info={el} key={index} id={id} />
      ))}
    </section>
  );
};

export default ProjectItems;
