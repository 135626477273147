import React, { useContext } from "react";
import style from "./style.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/free-mode";
import { FreeMode, Pagination, Navigation } from "swiper/modules";
import Project from "./project/Project";
import "swiper/css/navigation";
import { TailSpin } from "react-loader-spinner";
import { ProjectsCtrx } from "../store/projectListPrv";

const Projects = () => {
  const { filteredProjects } = useContext(ProjectsCtrx);

  const filteredProjectList = filteredProjects();

  return (
    <section id="ourProjects" className={`${style.projects} section`}>
      <div className={style.container}>
        <h2 className="section-title">
          ჩვენი <span className="text-primary">პროექტები</span>
        </h2>
      </div>
      <div className={`${style.projectsSection} section-content`}>
        <Swiper
          // slidesPerView={4}
          spaceBetween={0}
          freeMode={true}
          loop={true}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode, Pagination, Navigation]}
          className="mySwiper"
          breakpoints={{
            470: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 3,
            },
            1250: {
              slidesPerView: 4,
            },
          }}
        >
          {filteredProjectList?.map((el, index) => {
            return (
              <SwiperSlide key={index}>
                {el ? (
                  <Project info={el} />
                ) : (
                  <TailSpin
                    height="100px"
                    width="100px"
                    color="#4fa94d"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass={style.projectLoaders}
                    visible={true}
                  />
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};

export default Projects;
