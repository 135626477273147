import React, { useContext, useState, useEffect } from "react";
import style from "./style.module.css";
import { Link, NavLink } from "react-router-dom";
import { ProjectsCtrx } from "../store/projectListPrv";
import Logo from "../../asset/images/logo.jpg";

const NavigationMobile = () => {
  const { projectChoosed } = useContext(ProjectsCtrx);

  const [isFixed, setIsFixed] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 80) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    if (!collapsed) {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [collapsed]);

  return (
    <>
      <nav
        className={`${style.navMobile} navbar-mobile affix-top affix ${
          isFixed ? style.fixed : ""
        } ${isFixed ? style.fixedMobail : ""}`}
        style={{
          backgroundColor: collapsed ? "#fff" : "",
          zIndex: collapsed ? "99999999999" : "999999",
        }}
      >
        <button
          type="button"
          className={`navbar-toggle collapsed ${style.navBtnMenu}`}
          onClick={() => setCollapsed(!collapsed)}
        >
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
        <button
          type="button"
          className={`navbar-toggle collapsed ${style.inMenuButton}`}
          onClick={() => setCollapsed(!collapsed)}
          style={{ display: isFixed || collapsed ? "block" : "none" }}
        >
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
        <Link
          to="/"
          className={`${style.brandMobile} `}
          style={{ display: isFixed || collapsed ? "block" : "none" }}
        >
          <img src={Logo} alt="logo" />
        </Link>
        <div
          className={`${style.collapse} ${
            collapsed && style.navBarCollapse
          } navbar-collapse`}
        >
          <ul className={`${style.mobileNavbarMenu} navbar-nav-mobile`}>
            <li>
              <NavLink
                end
                to={`/${projectChoosed}` || "/"}
                className={({ isActive }) =>
                  isActive ? style.activeMobile : ""
                }
              >
                მთავარი
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/${projectChoosed}/about-company`}
                className={({ isActive }) =>
                  isActive ? style.activeMobile : ""
                }
              >
                ჩვენს შესახებ
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/${projectChoosed}/projects`}
                className={({ isActive }) =>
                  isActive ? style.activeMobile : ""
                }
              >
                პროექტები
              </NavLink>
            </li>

            <li>
              <NavLink
                to={`/${projectChoosed}/contact`}
                className={({ isActive }) =>
                  isActive ? style.activeMobile : ""
                }
              >
                კონტაქტი
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default NavigationMobile;
