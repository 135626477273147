import React, { useState, useContext } from "react";
import style from "./style.module.css";
import nextArrow from ".././../../asset/images/next.png";
import { useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import axios from "axios";
import { ProjectsCtrx } from "../../store/projectListPrv";

const Contact = ({ projects, bedRooms }) => {
  const [value, setValue] = useState();
  const [firstChildRemoved, setFirstChildRemoved] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const { filteredProjects } = useContext(ProjectsCtrx);

  const filteredProjectList = filteredProjects();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const projectListInfo = filteredProjectList.map((el) => el.title);

  const handleSelectFocus = (event) => {
    if (!firstChildRemoved) {
      event.target.removeChild(event.target.firstChild);
    }
    setFirstChildRemoved(true);
  };

  const onSubmit = (data) => {
    axios
      .post(
        `https://myprojects.api.cptlgroup.ge/api/contactSendMail`,
        {
          name: data.name,
          project: data.project,
          phone: value,
          bedrooms: data.bedrooms,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "https://cptlgroup.ge",
            " Access-Control-Allow-Credentials": true,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setMailSent(true);
          setTimeout(() => {
            setMailSent(false);
          }, 4000);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <section className="contacts section">
      <div className={`${style.container} containers`}>
        <section className="section-header">
          <h2 className={`section-title  ${style.contactTitle}`}>
            <span className={`text-primary`}>დაგვიკავშირდი</span>
          </h2>
          <strong className={`${style.fadeRight} fade-title-right`}>
            კონტაქტი
          </strong>
        </section>
        <div className={`${style.content} section-content`}>
          <div className={`col-address col-md-4 ${style.contantInfo}`}>
            <a href="tel:+995595339337">+995 595 339 337</a>
            <br />
            <a href="mailto: info@cptlgroup.ge"> info@cptlgroup.ge</a>
            <br />
            <p>თბილისი, ჭავჭავაძის 37ბ</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className={`${style.form}`}>
            <section className={`${style.inputs}`}>
              <article className={`${style.usernameEmail}`}>
                <div className={`${style.input}`}>
                  <input
                    type="text"
                    placeholder={"სახელი"}
                    id="username"
                    {...register("name", {
                      required: {
                        value: true,
                        message: "შეავსე ველი",
                      },
                      minLength: {
                        value: 1,
                        message: "მინიმუმ 1 სიმბოლო",
                      },
                    })}
                  />
                  {errors?.name && <p>{errors?.name?.message}</p>}
                </div>
              </article>
              <div className={`${style.input} ${style.phoneInputContainer}`}>
                <PhoneInput
                  country="GE"
                  value={value}
                  onChange={setValue}
                  international
                  defaultCountry="GE"
                  countryCallingCodeEditable={false}
                  error={
                    value
                      ? isValidPhoneNumber(value)
                        ? undefined
                        : "Invalid phone number"
                      : "Phone number required"
                  }
                />
              </div>

              {projects ? (
                <div className={`${style.input}  ${style.selectorContainer}`}>
                  <select
                    id="projects"
                    {...register("project")}
                    onFocus={handleSelectFocus}
                  >
                    <option value="" disabled selected>
                      აირჩიე პროექტი
                    </option>
                    {projectListInfo.map((el, id) => (
                      <option value={el} key={id}>
                        {el}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                ""
              )}

              {bedRooms ? (
                <div className={`${style.input}  ${style.selectorContainer}`}>
                  <select
                    id="bedrooms"
                    {...register("bedrooms")}
                    onFocus={handleSelectFocus}
                  >
                    <option value="" disabled selected>
                      საძინებლის რაოდენობა
                    </option>
                    <option value="აპარტმენტი სტილის">აპარტმენტი სტილის</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              ) : (
                ""
              )}
            </section>
            <button type="submit" className={`${style.btn} ${style.swings}`}>
              {!mailSent ? (
                <>
                  გაგზავნა <img src={nextArrow} alt="arrow"></img>
                </>
              ) : (
                <>
                  გაიგზავნა
                  <i
                    className="fa fa-check"
                    aria-hidden="true"
                    style={{ color: "green", fontSize: "16px" }}
                  ></i>
                </>
              )}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
