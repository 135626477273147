import React, { useContext } from "react";
import style from "./style.module.css";
import PageLeftHeader from "../../pagesHeader/pagesLeftHeader";
import Navigation from "../../navigation/Navigation";
import ProjetsInfo from "../../pagesMainHeaders/pagesInfo";
import ProjectDescription from "./projectDescription/ProjectDescription";
import ProjectItems from "./projectItems/ProjectItems";
import Contact from "../../footer/contact/Contact";
import CompanyInfo from "../../footer/companyInfo/Info";
import { useLocation } from "react-router";
import { ProjectsCtrx } from "../../store/projectListPrv";

const ProjectDetails = () => {
  const location = useLocation();
  const { filteredProjects } = useContext(ProjectsCtrx);

  const filteredProjectList = filteredProjects();

  const id = location.pathname.split("=")[1];
  const currentProject = filteredProjectList?.filter((el) => el._id === id)[0];

  return (
    <>
      <header className={`${style.headerContainer} `}>
        <PageLeftHeader />
        <Navigation />
      </header>
      <main className={`${style.main}`}>
        <ProjetsInfo
          title={currentProject?.title}
          image={currentProject?.image[0]}
          img={true}
        />

        <ProjectDescription currentProject={currentProject} />
        <ProjectItems currentProject={currentProject} />
      </main>

      <footer className="footer">
        <Contact projects={false} bedRooms={true} />
        <CompanyInfo />
      </footer>
    </>
  );
};

export default ProjectDetails;
