import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import style from "./style.module.css";
import axios from "axios";

const CallForm = () => {
  const { handleSubmit, control } = useForm();
  const [date, setDate] = useState("today");
  const [time, setTime] = useState("8:00");
  const [mailSent, setMailSent] = useState(false);

  const onSubmit = (data) => {
    axios
      .post(
        `https://myprojects.api.cptlgroup.ge/api/callRequestPopUp`,
        {
          name: data.name,
          phone: data.mobilePhone,
          date: date,
          time: time,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "https://cptlgroup.ge",
            " Access-Control-Allow-Credentials": true,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setMailSent(true);
          setTimeout(() => {
            setMailSent(false);
          }, 4000);
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <section className={`${style.inputSelectors}`}>
        <div className={`${style.input}`}>
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <select {...field} onChange={(e) => setDate(e.target.value)}>
                <option value="today" defaultChecked selected>
                  დღეს
                </option>
                <option value="tomorrow">ხვალ</option>
              </select>
            )}
          />
        </div>
        <div className={`${style.input}`}>
          <select name="time" onChange={(e) => setTime(e.target.value)}>
            <option value="08:00">8:00</option>
            <option value="09:00">9:00</option>
            <option value="10:00">10:00</option>
            <option value="11:00">11:00</option>
            <option value="12:00">12:00</option>
            <option value="13:00">13:00</option>
            <option value="14:00">14:00</option>
            <option value="15:00">15:00</option>
            <option value="16:00">16:00</option>
            <option value="17:00">17:00</option>
            <option value="18:00">18:00</option>
            <option value="19:00">19:00</option>
            <option value="20:00">20:00</option>
            <option value="21:00">21:00</option>
            <option value="22:00">22:00</option>
            <option value="23:00">23:00</option>
          </select>
        </div>
      </section>

      <div className={`${style.input}`}>
        <Controller
          name="mobilePhone"
          control={control}
          render={({ field }) => (
            <input type="tel" {...field} placeholder="ტელეფონის ნომერი" />
          )}
        />
      </div>
      <div className={`${style.input}`}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <input type="text" {...field} placeholder="სახელი" />
          )}
        />
      </div>
      <button type="submit" className={style.btn}>
        {!mailSent ? "დამირეკეთ" : "გაიგზავნა"}
      </button>
    </form>
  );
};

export default CallForm;
