import React, { useContext } from "react";
import style from "./style.module.css";
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCube, Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import { ProjectsCtrx } from ".././../../../store/projectListPrv";
import { TailSpin } from "react-loader-spinner";

const ItemHouse = ({ info, id }) => {
  const { projectChoosed } = useContext(ProjectsCtrx);

  return (
    <div className={`${style.card} ${style.card2}`}>
      <Link to={`/${projectChoosed}/projects/project=${id}/item=${info._id}`}>
        <Swiper
          effect={"cube"}
          grabCursor={true}
          loop={true}
          speed="500"
          cubeEffect={{
            shadow: false,
            slideShadows: false,
          }}
          navigation={true}
          pagination={true}
          modules={[EffectCube, Pagination, Navigation]}
          className={` ${style.swipers} swiper mySwiper`}
        >
          {info?.images.map((el, index) => {
            return (
              <SwiperSlide key={index}>
                <div className={`${style.swiperSlide} swiper-slide`}>
                  {info ? (
                    <img src={el} alt="img" />
                  ) : (
                    <TailSpin
                      height="100px"
                      width="100px"
                      color="#4fa94d"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass={style.projectLoaders}
                      visible={true}
                    />
                  )}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className={style.cardContent}>
          <h2>{info?.title}</h2>
          <p>{info?.kvPrice}$</p>
        </div>
      </Link>
    </div>
  );
};

export default ItemHouse;
