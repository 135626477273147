import React from "react";
import style from "./style.module.css";
import Header from "../header/Header";
import Projects from "../projects/Projects";
import Contact from "../footer/contact/Contact";
import CompanyInfo from "../footer/companyInfo/Info";
import About from "../about/About";
import MainHeader from "../header/mainHeader/mainHeader";

const RegionMenu = () => {
  return (
    <div className={style.sectionContainer}>
      <Header />
      <main className={`${style.mainSection} main`}>
        <MainHeader />
        <About />
        <Projects />
      </main>
      <footer className="footer">
        <Contact projects={true} bedRooms={false} />
        <CompanyInfo />
      </footer>
    </div>
  );
};

export default RegionMenu;
