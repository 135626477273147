import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.js";
import { CounterProvider } from "./components/store/counterPrv";
import { ProjectListProvider } from "./components/store/projectListPrv";
import ScrollToTop from "./helper/scrollToTop";

import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <ProjectListProvider>
          <CounterProvider>
            <ScrollToTop />
            <App />
          </CounterProvider>
        </ProjectListProvider>
      </Router>
    </QueryClientProvider>
  </React.StrictMode>
);
