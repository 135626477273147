import React, { useState, useContext } from "react";
import style from "./style.module.css";
import PagesLeftHeader from "../pagesHeader/pagesLeftHeader";
import Navigation from "../navigation/Navigation";
import Contact from "../footer/contact/Contact";
import CompanyInfo from "../footer/companyInfo/Info";
import PageHeaderTitle from "../pagesMainHeaders/pagesInfo";
import Project from "../projects/project/Project";
import projectDetailsImg from "../../asset/images/projectPage/project.jpg";
import { ProjectsCtrx } from "../store/projectListPrv";

const ProjectsPage = ({ projectDest }) => {
  const [projectCount, setprojectCount] = useState(8);
  const { filteredProjects } = useContext(ProjectsCtrx);

  const filteredProjectList = filteredProjects();

  const moreProjectsHandler = (e) => {
    e.preventDefault();

    setprojectCount((prev) => prev + 8);
  };

  return (
    <section className={`${style.projects} `}>
      <header className={`${style.headerContainer} `}>
        <PagesLeftHeader />
        <Navigation />
      </header>
      <main className={`${style.main}`}>
        <PageHeaderTitle
          title={`${projectDest} პროექტები`}
          image={projectDetailsImg}
        />
        <section className={`${style.projectList}`}>
          {filteredProjectList?.slice(0, projectCount).map((el, index) => (
            <article
              key={index}
              className={`${style.projectContainer} ${style.fadeIn}`}
              style={{ animationDelay: `${(0.06 * index) % 0.4}s` }}
            >
              <Project info={el} />
            </article>
          ))}
        </section>
        <div className={style.moreProjects}>
          <button onClick={moreProjectsHandler}>მეტის ნახვა</button>
        </div>
      </main>
      <footer className="footer">
        <Contact projects={false} bedRooms={true} />
        <CompanyInfo />
      </footer>
    </section>
  );
};

export default ProjectsPage;
