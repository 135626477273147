import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/zoom";
import style from "./style.module.css";
import { FreeMode, Navigation, Thumbs, Zoom } from "swiper/modules";
import Magnifier from "react-magnifier";

const ItemImageGallery = ({ itemInfo }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <section className={`${style.imageContainer} imageThumbContainer`}>
      <Swiper
        style={{
          "--swiper-navigation-color": "rgb(181, 140, 95)",
          "--swiper-pagination-color": "#fff",
        }}
        loop={true}
        spaceBetween={10}
        navigation={true}
        zoom={{ maxRatio: 5, toggle: true }}
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[FreeMode, Navigation, Thumbs, Zoom]}
        className={style.mySwiper2}
      >
        {itemInfo?.images?.map((el, index) => (
          <SwiperSlide key={index}>
            <Magnifier
              src={el}
              width={"100%"}
              height={"100%"}
              zoomFactor={1.5}
              mgShape={"square"}
              alt={`${el}`}
            />
            ;
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        loop={true}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className={`${style.mySwiper} imageThumbSwiper`}
      >
        {itemInfo?.images?.map((el, index) => (
          <SwiperSlide key={index}>
            <img src={el} alt={`${el}`} className={style.thumbnailImages} />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default ItemImageGallery;
