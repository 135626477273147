import React, { useContext } from "react";
import style from "./style.module.css";
import { Link, NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { ProjectsCtrx } from "../store/projectListPrv";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/keyboard";
import "swiper/css/mousewheel";

import {
  Pagination,
  Mousewheel,
  EffectFade,
  Autoplay,
  Navigation,
  Keyboard,
} from "swiper/modules";

const MainMenu = () => {
  const { setProjectChoosed, projectList } = useContext(ProjectsCtrx);

  return (
    <>
      <Swiper
        className={`${style.mySwiper} swiper-h btnNextArrow`}
        pagination={{
          clickable: true,
        }}
        simulateTouch
        navigation={true}
        keyboard={{
          enabled: true,
          onlyInViewport: false,
        }}
        // autoplay={{
        //   delay: 4000,
        //   disableOnInteraction: false,
        // }}
        modules={[Pagination, Navigation, Keyboard]}
      >
        <SwiperSlide>
          <Swiper
            className="mySwiper2 swiper-v"
            direction={"vertical"}
            effect="fade"
            lazy={true}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            keyboard={{
              enabled: true,
              onlyInViewport: false,
            }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            mousewheel={true}
            modules={[Pagination, Mousewheel, EffectFade, Autoplay, Keyboard]}
          >
            {projectList
              ?.filter((el) => el.projectRegion === "tbilisi")
              .map((el) => {
                return (
                  <SwiperSlide key={el._id}>
                    <NavLink
                      to="/tbilisi"
                      onClick={() => setProjectChoosed("tbilisi")}
                      className={style.mainLink}
                    >
                      <div className={style.imageContainer}>
                        <h2>თბილისის პროექტები</h2>
                        <Link to="/tbilisi" className={style.enterLink}>
                          ნახვა
                        </Link>
                        <img src={el?.image[0]} alt="project" loading="lazy" />
                      </div>
                    </NavLink>
                    <div className="swiper-lazy-preloader"></div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </SwiperSlide>

        <SwiperSlide>
          <Swiper
            className="mySwiper2 swiper-v"
            direction={"vertical"}
            effect="fade"
            keyboard={{
              enabled: true,
              onlyInViewport: false,
            }}
            lazy={true}
            mousewheel={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Mousewheel, EffectFade, Autoplay, Keyboard]}
          >
            {projectList
              ?.filter((el) => el.projectRegion === "batumi")
              .map((el) => {
                return (
                  <SwiperSlide key={el._id}>
                    <div className={style.imageContainer}>
                      <NavLink
                        to="/batumi"
                        onClick={() => setProjectChoosed("batumi")}
                        className={style.mainLink}
                      >
                        <h2>ბათუმის პროექტები</h2>
                        <Link to="/batumi" className={style.enterLink}>
                          ნახვა
                        </Link>
                        <img src={el?.image[0]} alt="project" loading="lazy" />
                      </NavLink>
                    </div>
                    <div className="swiper-lazy-preloader"></div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default MainMenu;
