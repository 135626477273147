import React from "react";
import style from "./style.module.css";
import logo from "../../../asset/images/logo.jpg";

const Contact = () => {
  return (
    <div className={` containers ${style.container}`}>
      <div className={style.text}>
        <img src={logo} alt="logo" />
      </div>

      <div className={style.rights}>
        © cptlgroup.ge 2023. All Rights Reserved.
      </div>
    </div>
  );
};

export default Contact;
