import "./App.css";
import React, { useContext, useEffect } from "react";
import MainMenu from "./components/mainMenu/MainMenu";
import { Route, Routes, useLocation } from "react-router";
import ProjectList from "./components/regionMenu/RegionMenu";
import ProjectDetails from "./components/projects/projectDetails/ProjectDetails";
import Projects from "./components/ProjectsPage/ProjectsPage";
import AboutPage from "./components/aboutPage/About";
import ContactPage from "./components/contactPage/ContactPage";
import { ProjectsCtrx } from "./components/store/projectListPrv";
import ProjectItemInformation from "./components/projects/projectItemInformation/ProjectItemInformation";
import axios from "axios";
import { useQuery } from "react-query";
import CallModal from "./components/popup/PopUp";

function App() {
  const { setProjectList, setProjectChoosed, projectChoosed } = useContext(
    ProjectsCtrx
  );
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("tbilisi")) {
      setProjectChoosed("tbilisi");
    } else if (location.pathname.includes("batumi")) {
      setProjectChoosed("batumi");
    } else {
      setProjectChoosed("tbilisi");
    }
  }, [projectChoosed, location.pathname]);

  const allProjects = useQuery(
    ["allProjects"],
    () =>
      // axios.get(`http://localhost:3001/api/allProjects`, {
      axios.get(` https://cptlserver.com.cptlgroup.ge/api/allProjects`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://cptlgroup.ge/",
          // "Access-Control-Allow-Origin": "http://localhost:3000",
          " Access-Control-Allow-Credentials": true,
        },
        withCredentials: true,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: true,
    }
  );

  useEffect(() => {
    if (allProjects.status === "success") {
      setProjectList(allProjects.data.data.projects);
    }
  }, [allProjects]);

  return (
    <div
      className={`App`}
      style={{ height: location.pathname === "/" ? "100vh" : "" }}
    >
      <CallModal />
      <Routes>
        <Route path="/" element={<MainMenu />} />
        <Route path={`/${projectChoosed}/*`}>
          <Route index element={<ProjectList />} />

          <Route
            path="projects"
            element={
              <Projects
                projectDest={
                  projectChoosed === "tbilisi"
                    ? "თბილისის"
                    : "შავი ზღვისპირეთის"
                }
              />
            }
          />
          <Route path="projects/:project" element={<ProjectDetails />} />
          <Route path="about-company" element={<AboutPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route
            path="projects/:project/:item"
            element={<ProjectItemInformation />}
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
