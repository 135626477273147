import React, { useContext } from "react";
import style from "./style.module.css";
import PagesLeftHeader from "../../pagesHeader/pagesLeftHeader";
import Navigation from "../../navigation/Navigation";
import Contact from "../../footer/contact/Contact";
import CompanyInfo from "../../footer/companyInfo/Info";
import projectDetailsImg from "../../../asset/images/projectPage/project.jpg";
import PageHeaderTitle from "../../pagesMainHeaders/pagesInfo";
import ItemImageGallery from "./itemImageGallery/ItemImageGallery";
import ItemImageInfo from "./itemImageInfo/ItemImageInfo";
import { useLocation } from "react-router";
import { ProjectsCtrx } from "../../store/projectListPrv";

const ProjectItemInformation = () => {
  const location = useLocation();
  const queryParams = location.pathname.split("=");
  const projectId = queryParams[1].split("/")[0];
  const itemId = queryParams[2];

  const { filteredProjects } = useContext(ProjectsCtrx);

  const filteredProjectList = filteredProjects();

  const projectInfo = filteredProjectList?.filter(
    (el) => el._id === projectId
  )[0];
  const itemInfo = projectInfo?.flats?.filter((el) => el._id === itemId)[0];

  return (
    <>
      <header className={`${style.headerContainer} `}>
        <PagesLeftHeader />
        <Navigation />
      </header>
      <main>
        <PageHeaderTitle title={itemInfo?.title} image={projectDetailsImg} />
        <section className={style.itemInfoContainer}>
          <ItemImageGallery itemInfo={itemInfo} />
          <ItemImageInfo itemInfo={itemInfo} />
        </section>
      </main>

      <footer className="footer">
        <Contact projects={false} bedRooms={true} />
        <CompanyInfo />
      </footer>
    </>
  );
};

export default ProjectItemInformation;
