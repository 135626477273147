import React from "react";
import style from "./style.module.css";

const PagesInfo = ({ title, image, img }) => {
  return (
    <>
      <section
        className={style.pagesInfo}
        style={{ backgroundImage: !img ? `url(${image})` : "" }}
      >
        {img && <img src={image} alt="project" />}
        <div className={style.pagesInfoTitle}>
          <h1>{title}</h1>
        </div>
      </section>
    </>
  );
};

export default PagesInfo;
