import React, { useState, useContext, useEffect } from "react";
import style from "./style.module.css";
import map from "../../asset/images/dottedMap.webp";
import { Link } from "react-router-dom";
import { ProjectsCtrx } from "../store/projectListPrv";

const AboutMap = () => {
  const [activeObject, setActiveObject] = useState(1);
  const { filteredProjects, projectChoosed } = useContext(ProjectsCtrx);

  const filteredProjectList = filteredProjects();

  const [objectPositions, setObjectPositions] = useState([]);

  console.log(projectChoosed);

  useEffect(() => {
    const positions = filteredProjectList?.map((el) => {
      const randomNumber =
        projectChoosed === "tbilisi"
          ? Math.floor(Math.random() * (48 - 38)) + 38
          : Math.floor(Math.random() * (68 - 62)) + 62;

      const randomNumberTop =
        projectChoosed === "tbilisi"
          ? Math.floor(Math.random() * (66 - 55)) + 55
          : Math.floor(Math.random() * (73 - 59)) + 59;

      return { right: randomNumber, top: randomNumberTop };
    });

    setObjectPositions(positions);
  }, [projectChoosed]);

  console.log(objectPositions);

  const handleLabelClick = (index) => {
    setActiveObject(activeObject === index ? null : index);
  };
  return (
    <section className={`${style.objects} section`}>
      <div className={` ${style.containerPadding} containers`}>
        <header className="section-header">
          <h2 className="section-title">
            ჩვენი პროექტები{" "}
            <span className="text-primary">
              {projectChoosed === "tbilisi" ? "თბილისში" : "შავი ზღვისპირეთში"}
            </span>
          </h2>
        </header>
        <div className="section-content">
          <div className={`${style.objects}`}>
            <img alt="map" className="img-responsive" src={map} />
            {filteredProjectList?.map((el, index) => {
              return (
                <div
                  key={el._id}
                  className={`${style.objectLabel} ${
                    activeObject === el._id ? style.active : ""
                  }`}
                  style={{
                    right: `${
                      objectPositions && objectPositions[index]?.right
                    }%`,
                    top: `${objectPositions && objectPositions[index]?.top}%`,
                  }}
                  onClick={() => handleLabelClick(el._id)}
                >
                  <Link to={`/${projectChoosed}/projects/project=${el._id}`}>
                    <div className={style.objectInfo}>
                      <h3 className={style.objectTitle}>{el.title}</h3>
                      <div className={style.objectContent}>{el.address}</div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMap;
