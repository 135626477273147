import React, { useContext } from "react";
import style from "./style.module.css";
import projectImgDefault from "../../../asset/images/tbilisi.jpg";
import { Link } from "react-router-dom";
import { ProjectsCtrx } from ".././../store/projectListPrv";

const Project = ({ info }) => {
  const { projectChoosed } = useContext(ProjectsCtrx);

  const title = info?.title.split(" ");
  const firstTitle = title
    ?.slice(0, title.length - 1)
    .join()
    .replaceAll(",", " ");
  const title2 = title?.slice(title.length - 1).join();
  const projectImg = info?.image[0] || projectImgDefault;
  const id = info?._id;

  return (
    <div className={`${style.project} ${style.projectLight}`}>
      <Link to={`/${projectChoosed}/projects/project=${id}`} title="project">
        <figure>
          <img alt="project" src={projectImg} />
          <figcaption>
            <h3 className={style.projectTitle}>
              {firstTitle}
              <br /> {title2}
            </h3>
            <h4 className={style.projectCategory}>პროექტი</h4>
            <div className={style.projectZoom}></div>
          </figcaption>
        </figure>
      </Link>
    </div>
  );
};

export default Project;
