import React from "react";
import style from "./style.module.css";
import arch from "../../../asset/images/icon-architecture.png";
import interior from "../../../asset/images/icon-interiors.png";
import planning from "../../../asset/images/icon-planing.png";

const Services = () => {
  return (
    <section className="services section">
      <div className="containers">
        <header className="section-header">
          <h2 className="section-title">
            <span className="text-primary">ჩვენი</span> სერვისები
          </h2>
        </header>
        <div className={style.sectionContent}>
          <div className={`row-services row-base row ${style.servicesLayout}`}>
            <div
              className={`col-base ${style.colService} col-sm-6 col-md-4  fadeInUp`}
            >
              <div className={style.serviceITem}>
                <img alt="arch" src={arch} />
                <h4>ბინის შერჩევა/შეძენა</h4>
                <p>
                  For each project we establish relationships with partners who
                  we know will help us create added value for your project. As
                  well as bringing together the public and private sectors, we
                  make sector-overarching links to gather knowledge and to learn
                  from each other. The way we undertake projects is based on
                  permanently applying.
                </p>
              </div>
            </div>
            <div
              className={`col-base ${style.colService} col-sm-6 col-md-4  fadeInUp`}
              data-wow-delay="0.3s"
            >
              <div className={style.serviceITem}>
                <img alt="interior" src={interior} />
                <h4>რემონტი</h4>
                <p>
                  For each project we establish relationships with partners who
                  we know will help us create added value for your project. As
                  well as bringing together the public and private sectors, we
                  make sector-overarching links to gather knowledge and to learn
                  from each other. The way we undertake projects is based on
                  permanently applying.
                </p>
              </div>
            </div>
            <div className="clearfix visible-sm"></div>
            {/* <div
              className={`col-base ${style.colService} col-sm-6 col-md-4 fadeInUp`}
              data-wow-delay="0.6s"
            >
              <div className={style.serviceITem}>
                <img alt="planning" src={planning} />
                <h4>გაქირავება</h4>
                <p>
                  For each project we establish relationships with partners who
                  we know will help us create added value for your project. As
                  well as bringing together the public and private sectors, we
                  make sector-overarching links to gather knowledge and to learn
                  from each other. The way we undertake projects is based on
                  permanently applying.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
