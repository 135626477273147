import React from "react";
import style from "./style.module.css";
import PagesLeftHeader from "../pagesHeader/pagesLeftHeader";
import Navigation from "../navigation/Navigation";
import Contact from "../footer/contact/Contact";
import CompanyInfo from "../footer/companyInfo/Info";
import Info from "./info/Info";
import projectDetailsImg from "../../asset/images/projectPage/project.jpg";
import PageHeaderTitle from "../pagesMainHeaders/pagesInfo";
import Services from "./services/Services";
import Map from "../map/Map";

const AboutPage = () => {
  return (
    <>
      <header className={`${style.headerContainer} `}>
        <PagesLeftHeader />
        <Navigation />
      </header>
      <main>
        <PageHeaderTitle
          title="კაპიტალ ჯგუფის შესახებ"
          image={projectDetailsImg}
        />
        <Info />
        <Services />
        <Map />
      </main>

      <footer className="footer">
        <Contact projects={true} bedRooms={false} />
        <CompanyInfo />
      </footer>
    </>
  );
};

export default AboutPage;
