import React from "react";

import style from "./style.module.css";
import GoogleMapComponent from "./googleMap/GoogleMap";

// AIzaSyCGnaItjyNaT86NPCm9dQXR_-DLw6lwSM8   api key

const ContactInfo = () => {
  return (
    <>
      <section className={style.contactDetails}>
        <div className={style.googleMap}>
          <GoogleMapComponent />
        </div>
        <div className={`${style.contactInfo}`}>
          <div className={style.contanctInfoContent}>
            <div className={style.contactInfoTitle}>კონტაქტი</div>
            <div className={` ${style.contactRow}`}>
              <i className={`${style.fa} fa fa-phone`}></i>
              <div className={style.contactBody}>
                <h4>ტელეფონი</h4>
                <div className={style.phoneRow}>
                  <a href="tel:+995595339337">+995 595 339 337</a>
                </div>
              </div>
            </div>
            <div className={` ${style.contactRow}`}>
              <i className={`${style.fa} fa fa-envelope`}></i>
              <div className={style.contactBody}>
                <h4>ელ.ფოსტა</h4>
                <div className={style.contactContent}>
                  <a href="mailto:info@cptlgroup.ge"> info@cptlgroup.ge</a>
                </div>
              </div>
            </div>
            <div className={` ${style.contactRow}`}>
              <i className={`${style.fa} fa fa-map-marker`}></i>
              <div className={style.contactBody}>
                <h4>მისამართი</h4>
                <div className={style.contactContent}>
                  <span>Tbilisi, Chavchavadze street 37b</span>
                  <span>თბილისი, ჭავჭავაძის 37ბ</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactInfo;
