import React, { useState, createContext, useCallback } from "react";

export const ProjectsCtrx = createContext({
  projectList: [],
  setProjectList: () => {},
  projectChoosed: "tbilisi",
  setProjectChoosed: () => {},
});

export const ProjectListProvider = ({ children }) => {
  const [projectList, setProjectList] = useState([]);
  const [projectChoosed, setProjectChoosed] = useState("tbilisi");

  const filteredProjects = useCallback(() => {
    const projects = projectList?.filter(
      (el) => el.projectRegion === `${projectChoosed}`
    );

    return projects;
  }, [projectChoosed, projectList]);

  return (
    <ProjectsCtrx.Provider
      value={{
        projectList,
        setProjectList,
        projectChoosed,
        setProjectChoosed,
        filteredProjects,
      }}
    >
      {children}
    </ProjectsCtrx.Provider>
  );
};
