import React from "react";
import style from "./style.module.css";
import arch from "../../asset/images/icon-architecture.png";
import interior from "../../asset/images/icon-interiors.png";
import planning from "../../asset/images/icon-planing.png";
import aboutImg from "../../asset/images/logo.jpg";

const About = () => {
  return (
    <section id="about" className={`${style.about} about section`}>
      <div className={`${style.container} containers`}>
        <header className="section-header">
          <h2 className="section-title">
            <span className="text-primary">CPTLGROUP.GE </span>-ის შესახებ
          </h2>
          <strong className={style.fadeLeft}>About</strong>
        </header>
        <div className={style.sectionContent}>
          <div className={`${style.aboutContent} ${style.specs}`}>
            <div className={`${style.lines}`}></div>
            <div className={`${style.linesRight}`}></div>

            <h3 className={style.aboutTitle}>
              კაპიტალ ჯგუფი
              <span className="text-primary">.</span>
            </h3>
            <div className={style.aboutInfo}>
              <p className={style.aboutInfoText}>
                კაპიტალ ჯგუფი აერთიანებს თბილისისა და აჭარის მაშტაბით მიმდინარე
                და დასრულებულ პროექტებს.
              </p>
            </div>
          </div>
          <div className={`${style.service} ${style.specs}`}>
            <h3 className={style.aboutTitle}>
              სპეციალიზაცია<span className="text-primary">:</span>
            </h3>
            <section className={style.serviceList}>
              <div className={`${style.serviceItem}`}>
                <img alt="service" width="46" src={arch} />
                <h4>ბინის შერჩევა/შეძენა </h4>
              </div>
              <div className={style.serviceItem}>
                <img alt="service" width="58" src={interior} />
                <h4>რემონტი </h4>
              </div>
              <div className={style.serviceItem}>
                <img alt="service" width="58" src={planning} />
                <h4>გაქირავება</h4>
              </div>
            </section>
          </div>
          <div className={`col-about-img ${style.specs} ${style.aboutImg}`}>
            <div className={`${style.lines}`}></div>
            <div className={`${style.linesRight}`}></div>

            <img alt="logo" className="img-responsive" src={aboutImg} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
