import React, { useContext } from "react";
import style from "./style.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { motion } from "framer-motion";
import { DashbCtrx } from "../../store/counterPrv";
import { ProjectsCtrx } from "../../store/projectListPrv";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/parallax";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

import {
  Navigation,
  Autoplay,
  Parallax,
  EffectFade,
  Pagination,
} from "swiper/modules";

const MainHeader = () => {
  const { setCurrentSlideshow, currentSlideShow } = useContext(DashbCtrx);
  const { filteredProjects, projectChoosed } = useContext(ProjectsCtrx);

  const filteredProjectList = filteredProjects();

  const handleSlideChange = (swiper) => {
    setCurrentSlideshow(swiper.realIndex);
  };

  return (
    <section className={`${style.mainHeader}`}>
      <Swiper
        effect="fade"
        fadeEffect={{ crossFade: true }}
        speed={2000}
        lazy={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        onSlideChange={handleSlideChange}
        parallax={true}
        modules={[Autoplay, Navigation, EffectFade, Parallax, Pagination]}
        loop={true}
      >
        {filteredProjectList.map((slide, index) => {
          const slideTitle = slide.title.split("");

          return (
            <SwiperSlide key={index}>
              <Link
                to={`/${projectChoosed}/projects/project=${slide._id}`}
                title={slide.title}
                className={style.linkToProject}
              >
                <div className={`${style.sliderContainer} swiper-slide`}>
                  <img src={slide.image[1]} alt="img2" loading="lazy" />
                  <div className="swiper-lazy-preloader"></div>

                  <div className={`${style.slideContent}`}>
                    <h2>
                      {slideTitle?.map((el, letterIndex) => (
                        <motion.span
                          key={letterIndex}
                          initial={{ x: "100%", opacity: 0 }}
                          animate={{
                            x: currentSlideShow === index ? 0 : "100%",
                            opacity: currentSlideShow === index ? 1 : 0,
                          }}
                          exit={{ opacity: 0 }}
                          transition={{
                            duration: 1.8,
                            delay:
                              index * 0.6 +
                              (slideTitle.length - letterIndex) * 0.1,
                            type: "spring",
                          }}
                        >
                          {el}
                        </motion.span>
                      ))}
                    </h2>

                    <motion.p
                      initial={{ y: 50, opacity: 0 }}
                      animate={{
                        y: currentSlideShow === index ? 0 : 50,
                        opacity: currentSlideShow === index ? 1 : 0,
                      }}
                      transition={{ duration: 1.5, delay: 0.2 }}
                      style={{
                        display: "inline-block",
                        zIndex: 111111111111,
                      }}
                      href="#ourProjects"
                      className={style.learnMore}
                    >
                      მეტის ნახვა <FontAwesomeIcon icon={faArrowRight} />
                    </motion.p>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default MainHeader;
