import React from "react";
import style from "./style.module.css";
import Navigation from "../navigation/Navigation";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <header className={`${style.headerContainer} `}>
      <NavLink className={`${style.brand}`} to="/" content="mainMenu">
        cptlgroup<span className="text-primary">.</span>ge
      </NavLink>
      <Navigation />
      <article className={style.mobailMenuCotnainer}>
        <div className={style.headerPhone}>
          <a href="tel:+995595339337" content="number">
            +995 595 339 337
          </a>
        </div>
        <ul className={style.socialList}>
          <li>
            <a
              href="https://www.facebook.com/capitalgroup01"
              className="fa fa-facebook"
              target="_blank"
              content="facebook"
              rel="noreferrer"
            ></a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/capital___group/?fbclid=IwAR2ZxgpvWsF3qlc58ddBzfBYaAcQgg2K-dyiwyxX4UPYjEg-VmPznUwiEOY"
              className="fa fa-instagram"
              target="_blank"
              content="instagram"
              rel="noreferrer"
            ></a>
          </li>
        </ul>
      </article>
    </header>
  );
};

export default Header;
